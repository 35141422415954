export default class Menu {
    constructor(el) {
        this.DOM = {
            el: el
        };

        if (!this.DOM.el) return;

        this.menuItems = [...this.DOM.el.children].filter(child => child.classList.contains('kg-menu__item--has-children'));

        this.menuItems2 = [...document.querySelectorAll('.kg-menu__submenu__item--has-children')];
        this.init();
    }
    

    /**
     * This function opens the submenu
     * @param el - The element that is being opened.
     */
    openSubmenu(el) {

        //if pour rgaa
        if (!el.classList.contains('is-submenu-visible')) {
            this.DOM.el.classList.add('is-submenu-visible');
            el.classList.add('is-submenu-visible');
        } else {
            this.closeSubmenu(el);
        }

    }

    /**
     * This function is used to close the submenu when the user clicks the back button
     * @param el - The element that was clicked.
     */
    closeSubmenu(el) {
        this.DOM.el.classList.remove('is-submenu-visible');
        setTimeout(() => {
            el.classList.remove('is-submenu-visible');
        }, 400);
    }

    /**
     * This function opens the submenu
     * @param el - The element that is being opened.
     */
    openSubmenu2(el) {
        this.DOM.el.classList.add('is-submenu-submenu-visible');
        el.classList.add('is-submenu-submenu-visible');
        el.parentNode.classList.add('is-submenu-submenu-desktop-visible');
    }

    /**
     * This function is used to close the submenu when the user clicks the back button
     * @param el - The element that was clicked.
     */
    closeSubmenu2(el) {
        this.DOM.el.classList.remove('is-submenu-submenu-visible');
        el.parentNode.classList.remove('is-submenu-submenu-desktop-visible');
        setTimeout(() => {
            el.classList.remove('is-submenu-submenu-visible');
        }, 400);

    }

    /**
     * The toggleSubmenuEvent function takes an element as an argument and adds an event listener to the
     * element's toggle submenu button
     * @param el - The element that was clicked.
     * @returns Nothing.
     */
    toggleSubmenuEvent(el) {
        const $toggle = el.querySelector('.kg-menu__item-toggle-submenu-event');
        if ( !$toggle ) {
            return;
        }
        $toggle.addEventListener('click', (e) => this.openSubmenu(el));

        // Handling keyboard navigation
        const $trigger = el.querySelector('.kg-menu__item-toggle-submenu-trigger');
        if ( !$trigger ) {
            return;
        }
        $trigger.addEventListener("keydown", (e) => {
            if (event.key === "Enter" || event.key === " ") { // Space or Enter key
                e.preventDefault();
                this.openSubmenu(el);
            }
        });
    }

    /**
     * It adds an event listener to the back button in the submenu.
     * @param el - The element that was clicked.
     * @returns Nothing.
     */
    toggleButtonBackEvent(el) {
        const $toggle = el.querySelector('.kg-menu__submenu__back-button-event');
        if ( !$toggle ) {
            return;
        }
        $toggle.addEventListener('click', (e) => this.closeSubmenu(el));

        const $trigger = el.querySelector('.kg-menu__submenu__back-button-trigger');
        if ( !$trigger ) {
            return;
        }
        $trigger.addEventListener('click', (e) => {
            e.preventDefault();
            this.closeSubmenu(el)
        });
    }

    /**
     * The toggleSubmenuEvent function takes an element as an argument and adds an event listener to the
     * element's toggle submenu button
     * @param el - The element that was clicked.
     * @returns Nothing.
     */
    toggleSubmenuEvent2(el) {
        const $toggle = el.querySelector('.kg-menu__submenu__item-toggle-submenu-event');
        if ( !$toggle ) {
            return;
        }
        $toggle.addEventListener('click', (e) => this.openSubmenu2(el));

        const $trigger = el.querySelector('.kg-menu__submenu__item-toggle-submenu-trigger');
        if ( !$trigger ) {
            return;
        }
        $trigger.addEventListener('click', (e) => {
            e.preventDefault();
            this.openSubmenu2(el)
        });
    }

    /**
     * It adds an event listener to the back button in the submenu.
     * @param el - The element that was clicked.
     * @returns Nothing.
     */
    toggleButtonBackEvent2(el) {
        const $toggle = el.querySelector('.kg-menu__submenu__submenu__back-button-event');
        if ( !$toggle ) {
            return;
        }
        $toggle.addEventListener('click', (e) => this.closeSubmenu2(el));

        const $trigger = el.querySelector('.kg-menu__submenu__submenu__back-button-trigger');
        if ( !$trigger ) {
            return;
        }
        $trigger.addEventListener('click', (e) => {
            e.preventDefault();
            this.closeSubmenu2(el)
        });
    }

    init() {
        this.menuItems.forEach((item) => {
            this.toggleSubmenuEvent(item);
            this.toggleButtonBackEvent(item);
        });
        this.menuItems2.forEach((item) => {
            this.toggleSubmenuEvent2(item);
            this.toggleButtonBackEvent2(item);
        });
    }
}
