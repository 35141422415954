import Accessibilite from "./modules/Accessibilite";
import AccessNav from "./modules/AccessNav";
import Menu from './modules/Menu';
import Hidden from './modules/Hidden';
import InitUIkit from './modules/UIkit';
import KGToggleNavigation from './modules/KGToggleNavigation';
import KGNavigationFooter from './modules/KGNavigationFooter';

// CSS
import '../scss/styles.scss';


/**
 * Init Function on document Ready
 */
const initReady = () => {
    // Always scroll to top when page is loaded
    //document.documentElement.scrollTo(0, 0);

    Accessibilite();
    AccessNav();
    new Menu( document.querySelector('.kg-menu') );

    KGNavigationFooter();

    KGToggleNavigation();

    // Accessibility
    Hidden();

    // UIKit Functions
    InitUIkit();

};

// Document ready
document.addEventListener('DOMContentLoaded', initReady);
