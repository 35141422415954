import { breakpoint, hiddenVisibleClasses, calcWinsize, debounce } from '../Helpers';

// Calc Winsize.
let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

const Hidden = () => {
    // Get UIkit hidden and visible css classes by breakpoints.
    const cssClasses = hiddenVisibleClasses();

    /**
     * Set aria hidden attribute to true
     *
     * @param {HTMLElement} el
     */
    function setAriaHidden(el) {
        el.setAttribute('aria-hidden', 'true');
    }

    /**
     * Set aria hidden attribute to false
     *
     * @param {HTMLElement} el
     */
    function setAriaVisible(el) {
        el.setAttribute('aria-hidden', 'false');
    }

    /**
     * Set HTML element attribute wityh callback function
     *
     * @param {*} cssClass
     * @param {*} callback
     */
    function setElsAttributes(cssClass, callback) {
        const $els = [...document.querySelectorAll(`.${cssClass}`)];

        $els.forEach(el => { debounce(callback(el), 200) });
    }

    /**
     * Loop through all breakpoint
     */
    function loopBreakpoints() {
        for (const key in breakpoint) {
            if ( winsize.width > breakpoint[key] ) {
                setElsAttributes(cssClasses[key].visible, setAriaVisible);
                setElsAttributes(cssClasses[key].hidden, setAriaHidden);
            } else {
                setElsAttributes(cssClasses[key].hidden, setAriaVisible);
                setElsAttributes(cssClasses[key].visible, setAriaHidden);
            }
        }
    }

    // Init.
    loopBreakpoints();

    // Launch init on resize.
    window.addEventListener('resize', loopBreakpoints);
}

export default Hidden;
