
/**
 * Accessibilite Navigation
 */
const AccessNav = () => {

    let lastFocusedElement = null;

    /**
     * Fonction pour cacher les sous-menus et ramener le focus sur l'élément approprié
     */
    function hideSubmenus() {
        const submenus = document.querySelectorAll('.kg-menu__submenu-wrapper, .kg-menu__submenu__submenu-wrapper');
        submenus.forEach(submenu => {
            submenu.setAttribute('aria-hidden', 'true');
        });

        const itemsWithFocus = document.querySelectorAll('.kg-menu__item--focus, .kg-menu__submenu__item--active');
        itemsWithFocus.forEach(item => {
            item.classList.remove('kg-menu__item--focus', 'kg-menu__submenu__item--active');
        });

        // Retirer les classes spécifiques aux éléments avec sous-menus visibles
        const visibleSubmenus = document.querySelectorAll('.is-submenu-visible, .is-submenu-submenu-visible, .is-submenu-submenu-desktop-visible');
        visibleSubmenus.forEach(item => {
            item.classList.remove('is-submenu-visible', 'is-submenu-submenu-visible', 'is-submenu-submenu-desktop-visible');
        });

        // Réinitialiser les attributs aria-expanded
        const expandedItems = document.querySelectorAll('[aria-expanded="true"]');
        expandedItems.forEach(item => {
            item.setAttribute('aria-expanded', 'false');
        });

        // Renvoyer le focus à l'élément précédemment sélectionné
        if (lastFocusedElement) {
            lastFocusedElement.focus();
        }
    }

    /**
     * Gestion de l'accessibilité pour sortir des sous-menus avec la touche Échap
     */
    document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            hideSubmenus();
        }
    });

    /**
     * Gestion du focus lorsqu'un élément reçoit le focus
     */
    document.addEventListener('focusin', function(event) {
        const focusedElement = event.target;
        if (focusedElement.classList.contains('kg-menu__item') || focusedElement.closest('.kg-menu__item')) {
            lastFocusedElement = focusedElement;
        }
    });
}

export default AccessNav;