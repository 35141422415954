/**
 * Helpers
 */

/** Breakpoints list */
export const breakpoint = {
    'small': 640,
    'medium': 960,
    'large': 1200,
    'xlarge': 1600,
};

/**
 * Hidden / Visible Classes
 *
 * Generate an Object of all
 */
export const hiddenVisibleClasses = () => {
    const classes = {};

    const modifiers = {
        'small': '@s',
        'medium': '@m',
        'large': '@l',
        'xlarge': '@xl',
    };

    for (const [key, value] of Object.entries(modifiers)) {
        classes[key] = {
            'visible': `uk-visible\\${value}`,
            'hidden': `uk-hidden\\${value}`,
        };
    }

    return classes;
};

/**
 * Return window size
 */
export const calcWinsize = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
};

/**
 * Debounce Function
 * @param {Function} callback
 * @param {String} wait
 * @returns
 */
export const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
};