import { calcWinsize, breakpoint } from '../Helpers';

// UIKit Core
import UIkit from 'uikit/dist/js/uikit-core';

// Uikit Components
import Tooltip from 'uikit/dist/js/components/tooltip';
import Lightbox from 'uikit/dist/js/components/lightbox';
import Filter from 'uikit/dist/js/components/filter';
import Notification from "uikit/dist/js/components/notification";

// Activate Components
UIkit.component('tooltip', Tooltip);
UIkit.component('lightbox', Lightbox);
UIkit.component('filter', Filter);
UIkit.component('notification', Notification);

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());


function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * Init Function on document Ready
 */
const InitUIkit = () => {

    //AlertePopup();
};

export default InitUIkit;
