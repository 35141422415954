
/**
 * Accessibilite
 */
const Accessibilite = () => {

    const $buttonToggleContrast = document.querySelector('.event-switch-contrast');
    if (!$buttonToggleContrast) {
        return false;
    }

    $buttonToggleContrast.addEventListener('change', setContrast);

    function setContrast(e) {

        if (e.currentTarget.checked) {
            document.body.classList.add('theme-contrast');
            setCookie('contrast',1,0.5);
        } else {
            document.body.classList.remove('theme-contrast');
            setCookie('contrast',0,0.5);
        }
    }

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}


export default Accessibilite;