import UIkit from 'uikit/dist/js/uikit-core';
import { breakpoint, calcWinsize } from '../Helpers';

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

/**
 * Toggle Header Offcanvas
 */
export default function KGNavigationFooter() {

    const $navigations_footer = [...document.querySelectorAll('.site-navigation-footer')];

    if ( !$navigations_footer ) {
        return;
    }

    init();
    window.addEventListener('resize', init);

    function initNav(navigation) {
        let $subnav = navigation.querySelector('.uk-nav-sub');

        // Initialize Nav
        UIkit.nav(navigation);

        // Destroy Nav
        if ( winsize.width > breakpoint.medium ) {
            UIkit.nav(navigation).$destroy();
            $subnav.removeAttribute('hidden');
            $subnav.removeAttribute('aria-hidden');
        }
    }

    function init() {
        $navigations_footer.forEach(navigation => initNav(navigation));
    }





}