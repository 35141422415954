/**
 * Toggle Header Offcanvas
 */
export default function KGToggleNavigation() {
    const $togglesMenu = document.querySelectorAll('[kg-toggle-navigation]');
    const $offcanvas = document.querySelector('#kg-offcanvas-navigation');

    if ( !$togglesMenu.length && !$offcanvas ) {
        return;
    }

    //const $offcanvasClose = $offcanvas.querySelector('[kg-close-navigation]');

    /**
     * Toggle Canvas Attribute
     */
    function toggleOffcanvasAttribute() {
        if ( $offcanvas.getAttribute('aria-hidden') === 'false' ) {
            $offcanvas.setAttribute('aria-hidden', 'true');
        } else {
            $offcanvas.setAttribute('aria-hidden', 'false');
        }
    }

    /**
     * Toggle Button Attribute
     */
    function toggleButtonAttribute(button) {
        if ( button.getAttribute('aria-expanded') === 'false' ) {
            button.setAttribute('aria-expanded', 'true');
        } else {
            button.setAttribute('aria-expanded', 'false');
        }
    }

    // Event On Toggle Menu click event
    $togglesMenu.forEach((button) => {
        button.addEventListener('click', (e) => {


            toggleOffcanvasAttribute();
            toggleButtonAttribute(button);

            if ( !$offcanvas.classList.contains('is-visible') ) {
                $offcanvas.classList.add('is-visible');
                button.classList.add('is-active');
                document.documentElement.classList.add('uk-overflow-hidden', 'is-offcanvas-open');
            } else {
                $offcanvas.classList.remove('is-visible');
                button.classList.remove('is-active');
                document.documentElement.classList.remove('uk-overflow-hidden', 'is-offcanvas-open');
            }
        });
    });

    /*$offcanvasClose.addEventListener('click', e => {
        document.documentElement.classList.remove('uk-overflow-hidden', 'is-offcanvas-open');
        $offcanvas.classList.remove('is-visible');
        toggleOffcanvasAttribute();
    });*/
};
